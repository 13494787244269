import React from "react";
import { Col, Container, Row, Tab, Nav } from "react-bootstrap";
import styled from "styled-components";

interface Props {
  bannerHeading: string;
  bannerTextLead: string;
  tabHeading1: string;
  tabHeading2: string;
  tabHeading3: string;
  tabTextContent1: string;
  tabTextContent2: string;
  tabTextContent3: string;
  tabImagePath1: string;
  tabImagePath2: string;
  tabImagePath3: string;
  id?: string;
  className?: string;
  colClassName?: string;
  headingStyle?: any;
  textLeadStyle?: any;
}

const HomeTab = styled.div`
  .nav-pills {
    counter-reset: number;

    @media only screen and (max-width: 767px) {
      counter-reset: list;
    }
  }
`;

const StyledNavItem = styled(Nav.Item)`
  position: relative;
  counter-increment: number;
  margin-bottom: 10px;

  &:nth-child(1) {
    .active {
      h4 {
        &::before {
          color: rgba(145, 66, 161, 0.5);
        }
      }
    }
  }

  &:nth-child(2) {
    .active {
      h4 {
        &::before {
          color: rgba(0, 158, 161, 0.5);
        }
      }
    }
  }

  &:nth-child(3) {
    .active {
      h4 {
        &::before {
          color: #fcc7ad;
        }
      }
    }
  }
`;

const StyledNavLink = styled(Nav.Link)`
  padding: 25px;
  position: relative;
  border-left: 5px solid transparent !important;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  cursor: pointer;

  &::before {
    content: "0" counter(number) " ";
    position: absolute;
    left: -80px;
    color: #c799d1;
    font-size: 50px;
    line-height: 50px;
    top: 30%;
    font-family: ProximaNova-Semibold;
    text-align: right;
    height: 61px;
    width: 60px;
    opacity: 0;

    @media only screen and (max-width: 1180px) and (min-width: 991px) {
      display: none !important;
    }

    @media only screen and (max-width: 810px) {
      display: none !important;
    }
  }

  &.active {
    background-color: #fff !important;
    -webkit-box-shadow: 0 2px 21px 0 rgb(0 0 0 / 9%);
    box-shadow: 0 2px 21px 0 rgb(0 0 0 / 9%);
    border-left: 5px solid #c799d1 !important;

    &::before {
      opacity: 1;
    }

    &::after {
      content: "";
      position: absolute;
      left: -5px;
      top: 0;
      bottom: 50%;
      width: 5px;
      background-color: #9142a1;
      border-radius: 0.25rem 0 0 0;
    }

    h4 {
      color: #9142a1;
    }
  }

  &:hover {
    -webkit-box-shadow: 0 2px 21px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 21px 0 rgba(0, 0, 0, 0.09);
  }
`;

const StyledNavLink2 = styled(StyledNavLink)`
  &::before {
    color: #009ea1;
  }

  &.active {
    border-color: #82e3e0 !important;

    h4 {
      color: #009ea1;
    }

    &::after {
      background-color: #009ea1;
    }
  }
`;

const StyledNavLink3 = styled(StyledNavLink)`
  &::before {
    color: #db0163;
  }

  &.active {
    border-color: #fcc7ad !important;

    h4 {
      color: #db0163;
    }

    &::after {
      background-color: #db0163;
    }
  }
`;

const TabWrap = styled.section`
  margin-top: 40px;
  margin-bottom: 60px !important;
`;

const BannerHeading = styled.h2`
  color: #282828 !important;

  @media only screen and (max-width: 991px) {
    color: #282828 !important;
    text-align: center;
    margin-top: 40px;
  }
`;

const TextLead = styled.p`
  font-family: ProximaNova-Regular;
  font-size: 18px;
  color: #424242;

  @media only screen and (max-width: 991px) {
    margin-bottom: 40px;
  }
`;

const TextLeadPara = styled.p`
  margin-bottom: 0;
  font-family: ProximaNova-Regular;
  color: #5b5b5b;
`;

const TabHeading = styled.h4`
  font-family: ProximaNova-Semibold;
  color: #282828;

  @media (max-width: 1199px) {
    font-size: 20px !important;
    padding: 0;
  }

  &::before {
    @media only screen and (max-width: 767px) {
      counter-increment: list;
      content: "0" counter(list, number) ". ";
      color: #333;
    }
  }
`;

const StyledTabContent = styled(Tab.Content)`
  @media only screen and (max-width: 767px) {
    padding-bottom: 30px;
  }
`;

const LiAttrbutes = {
  "data-sal": "fade",
  "data-sal-duration": "1000",
};

const SectionTabs: React.FC<Props> = ({
  bannerHeading,
  bannerTextLead,
  tabHeading1,
  tabHeading2,
  tabHeading3,
  tabTextContent1,
  tabTextContent2,
  tabTextContent3,
  tabImagePath1,
  tabImagePath2,
  tabImagePath3,
  id,
  className,
  colClassName,
  headingStyle,
  textLeadStyle,
}) => {
  return (
    <>
      <TabWrap className={className} id={id}>
        <Container>
          <Row className="justify-content-md-center">
            <Col className={colClassName}>
              <Row>
                <Col xl={6} lg={12}>
                  <BannerHeading style={headingStyle}>
                    {bannerHeading}
                  </BannerHeading>
                  <TextLead style={textLeadStyle}>{bannerTextLead}</TextLead>
                </Col>
              </Row>

              <Tab.Container id="myTabs" defaultActiveKey="home-tab">
                <Row>
                  <Col md={6} lg={6}>
                    <HomeTab className="homeTab">
                      <Nav as="ul" variant="pills" className="flex-column">
                        <StyledNavItem
                          as="li"
                          {...LiAttrbutes}
                          data-sal-delay="0"
                        >
                          <StyledNavLink eventKey="home-tab">
                            <TabHeading>{tabHeading1}</TabHeading>
                            <TextLeadPara>{tabTextContent1}</TextLeadPara>
                          </StyledNavLink>
                        </StyledNavItem>
                        <StyledNavItem
                          as="li"
                          {...LiAttrbutes}
                          data-sal-delay="400"
                        >
                          <StyledNavLink2 eventKey="profile-tab">
                            <TabHeading>{tabHeading2}</TabHeading>
                            <TextLeadPara>{tabTextContent2}</TextLeadPara>
                          </StyledNavLink2>
                        </StyledNavItem>
                        <StyledNavItem
                          as="li"
                          {...LiAttrbutes}
                          data-sal-delay="800"
                        >
                          <StyledNavLink3 eventKey="contact-tab">
                            <TabHeading>{tabHeading3}</TabHeading>
                            <TextLeadPara>{tabTextContent3}</TextLeadPara>
                          </StyledNavLink3>
                        </StyledNavItem>
                      </Nav>
                    </HomeTab>
                  </Col>

                  <Col md={6} lg={6}>
                    <StyledTabContent>
                      <Tab.Pane eventKey="home-tab">
                        <img
                          src={tabImagePath1}
                          width={493}
                          height={357}
                          className="img-fluid"
                          alt="image"
                          loading="lazy"
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="profile-tab">
                        <img
                          src={tabImagePath2}
                          className="img-fluid"
                          alt=""
                          loading="lazy"
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="contact-tab">
                        <img
                          src={tabImagePath3}
                          className="img-fluid"
                          alt=""
                          loading="lazy"
                        />
                      </Tab.Pane>
                    </StyledTabContent>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </TabWrap>
    </>
  );
};

export default SectionTabs;
