import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

const SectionWrap = styled.section`
  background: #f5f8fc;
  padding: 80px 0;

  h2 {
    color: #282828;
    font-size: 24px;
    line-height: 29px;
    font-weight: 600 !important;
    font-family: ProximaNova-Light !important;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 767px) {
    padding: 80px 30px !important;
  }
`;

const FeatureList = styled.div``;

const ListItem = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  table-layout: fixed;
  list-style-type: none;
  list-style-position: outside;
  padding: 0.21428571em 0;
  line-height: 1.14285714em;
  margin-bottom: 20px;
  position: relative;

  &::before {
    content: "\\2713";
    margin: 5px 10px 0 0;
    background-color: #9142a1;
    min-width: 17px;
    height: 17px;
    display: inline-block;
    color: #fff;
    text-align: center;
    line-height: 21px;
    border-radius: 2px;
  }
`;

const TextContent = styled.div`
  color: #424242;
  font-family: ProximaNova-Regular;
  line-height: 24px !important;
  font-size: 16px !important;
`;

const SectionFeature: React.FC = () => {
  return (
    <>
      <SectionWrap>
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <h2>Features</h2>
              <Row>
                <Col md={4}>
                  <FeatureList
                    data-sal="fade"
                    data-sal-delay="0"
                    data-sal-duration="1000"
                  >
                    <ListItem>
                      <TextContent>
                        Discover charities and Giving Groups that you might be
                        interested in
                      </TextContent>
                    </ListItem>
                  </FeatureList>
                </Col>
                <Col md={4}>
                  <FeatureList
                    data-sal="fade"
                    data-sal-delay="0"
                    data-sal-duration="1000"
                  >
                    <ListItem>
                      <TextContent>
                        View your charitable giving history in one place
                      </TextContent>
                    </ListItem>
                  </FeatureList>
                </Col>
                <Col md={4}>
                  <FeatureList
                    data-sal="fade"
                    data-sal-delay="0"
                    data-sal-duration="1000"
                  >
                    <ListItem>
                      <TextContent>
                        Get support with your giving from our team—we're here to
                        help
                      </TextContent>
                    </ListItem>
                  </FeatureList>
                </Col>
                <Col md={4}>
                  <FeatureList
                    data-sal="fade"
                    data-sal-delay="0"
                    data-sal-duration="1000"
                  >
                    <ListItem>
                      <TextContent>
                        Give with friends, family, and others creating change
                      </TextContent>
                    </ListItem>
                  </FeatureList>
                </Col>
                <Col md={4}>
                  <FeatureList
                    data-sal="fade"
                    data-sal-delay="0"
                    data-sal-duration="1000"
                  >
                    <ListItem>
                      <TextContent>
                        Show or hide your contact information when you give and
                        manage your relationships with charities
                      </TextContent>
                    </ListItem>
                  </FeatureList>
                </Col>
                <Col md={4}>
                  <FeatureList
                    data-sal="fade"
                    data-sal-delay="0"
                    data-sal-duration="1000"
                  >
                    <ListItem>
                      <TextContent>
                        Donate, invest, and grow assets with an accompanying
                        Charitable Investment Account so you have more to give
                        away
                      </TextContent>
                    </ListItem>
                  </FeatureList>
                </Col>
                <Col md={4}>
                  <FeatureList
                    data-sal="fade"
                    data-sal-delay="0"
                    data-sal-duration="1000"
                  >
                    <ListItem>
                      <TextContent>
                        Receive a tax receipt when you add money to your account
                      </TextContent>
                    </ListItem>
                  </FeatureList>
                </Col>
                <Col md={4}>
                  <FeatureList
                    data-sal="fade"
                    data-sal-delay="0"
                    data-sal-duration="1000"
                  >
                    <ListItem>
                      <TextContent>
                        Set it and forget it with automatic deposits and gifts
                      </TextContent>
                    </ListItem>
                  </FeatureList>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </SectionWrap>
    </>
  );
};

export default SectionFeature;
