import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "gatsby";
import { VCenter } from "../../styles/globalStyles";
import { StaticImage } from "gatsby-plugin-image";

const SectionWrap = styled.section`
  ${VCenter}
  background-color: #225fdd;
  background-image: url(/bg/swiggle.svg);
  min-height: 373px;

  .v-center {
    position: relative;
  }
`;

const TopChatImage = styled.div`
  position: absolute;
  top: -150px;
  right: 0;
  display: inline-block;
  vertical-align: bottom;

  .gatsby-image-wrapper {
    display: inline-block;
    & + .gatsby-image-wrapper {
      position: absolute;
      bottom: -10px;
      left: -23px;
    }
  }
`;

const GetInTouch = styled.div`
  display: inline-block;
  margin: auto;
  max-width: 640px;

  &::before,
  &::after {
    content: "";
    position: absolute;
  }

  &::before {
    background-image: url(/index/chat-left.png);
    height: 108px;
    width: 149px;
    background-size: cover;
    background-repeat: no-repeat;
    left: -22px;
    bottom: -15px;

    @media only screen and (max-width: 1199px) and (min-width: 767px) {
      left: -155px;
      bottom: -15px;
    }

    @media only screen and (max-width: 767px) {
      left: 0;
      bottom: -175px;
    }
  }

  &::after {
    background-image: url(/index/canada-icon.png);
    height: 80px;
    width: 82px;
    background-size: cover;
    background-repeat: no-repeat;
    right: -22px;
    bottom: -15px;

    @media only screen and (max-width: 1199px) and (min-width: 767px) {
      right: -100px;
    }

    @media only screen and (max-width: 767px) {
      bottom: -130px;
      right: 0;
      z-index: 3;
    }
  }

  h2 {
    color: #fff;

    & + .lead {
      color: #fff;
      font-size: 18px;
    }
  }
`;

const SectionBlueComp: React.FC = () => {
  return (
    <>
      <SectionWrap>
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <div className="v-center">
                <TopChatImage>
                  <StaticImage
                    src="../../images/index/chat-right.png"
                    width={148}
                    height={108}
                    alt=""
                    objectFit="contain"
                    quality={100}
                    placeholder="none"
                    loading='eager'
                  />
                  <StaticImage
                    src="../../images/index/ribbon-icon.png"
                    width={58}
                    height={53}
                    alt=""
                    objectFit="contain"
                    quality={100}
                    placeholder="none"
                    loading='eager'
                  />
                </TopChatImage>
                <GetInTouch
                  className="text-center"
                  data-sal="fade"
                  data-sal-delay="0"
                  data-sal-duration="1000"
                >
                  <h2>Get support with making your biggest impact</h2>
                  <p className="lead">
                    From getting the most out of your account to building a
                    personalized giving plan, we’re here to help.
                  </p>
                  <p className="text-center">
                    <Link className="btn btn-white" to="/contact">
                      Get in touch
                    </Link>
                  </p>
                </GetInTouch>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionWrap>
    </>
  );
};

export default SectionBlueComp;
