import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

interface Props {
  text: string;
  Linkto: string;
  className?: string;
  style?: any;
  children?: any;
}

const CtaLink = styled(Link)`
  background-clip: padding-box;
  background-color: #035ce5;
  color: #fff;
  font-size: 16px;
  min-width: 225px;
  position: relative;
  z-index: 2;
  border-radius: 20.5px;
  padding: 8.5px 40px;
  transition: all 0.3s ease-out;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  font-family: ProximaNova-Regular;

  &::before {
    background-color: rgba(3, 92, 229, 0.4);
    border-radius: 35px;
    bottom: -10px;
    content: "";
    left: -10px;
    position: absolute;
    right: -10px;
    top: -10px;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    z-index: -1;
  }

  &:hover {
    color: #fff;
    background-color: #0050cc;

    &::before {
      bottom: -15px;
      left: -15px;
      right: -15px;
      top: -15px;
    }
  }

  @media (max-width: 1199px) {
    font-size: 14px;
  }
`;

const ButtonWithLink: React.FC<Props> = ({
  text,
  Linkto,
  className,
  style,
  children,
}) => {
  return (
    <CtaLink to={Linkto} style={style} className={className}>
      {text}
      {children}
    </CtaLink>
  );
};

export default ButtonWithLink;
