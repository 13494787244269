import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

const greyBg = "#f5f8fc";

const SectionConnect = styled.section`
  padding: 80px 0;
  background: ${greyBg};

  h2 {
    color: #282828;
    font-size: 24px;
    line-height: 29px;
    font-weight: 600 !important;
    font-family: ProximaNova-Light !important;
    margin-bottom: 20px;
  }

  p {
    text-align: left;
    font-family: ProximaNova-Regular;
  }

  @media (max-width: 420px) {
    padding: 59px 10px !important;
  }
`;

const ListItem = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  table-layout: fixed;
  list-style-type: none;
  list-style-position: outside;
  padding: 0.21428571em 0;
  line-height: 1.14285714em;
  margin-bottom: 20px;

  .image {
    isplay: inline-block;
    vertical-align: top;
    width: 3em;
    height: 3em;
    margin-top: 5px;

    & + .content {
      display: inline-block;
      width: auto;
      margin-left: 1rem;
      font-size: 18px;
      line-height: 28px;
      color: #67686c;
      font-family: ProximaNova-Regular;

      b {
        font-family: ProximaNova-Semibold;
      }

      @media only screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
  }
`;

const SectionTwo: React.FC = () => {
  return (
    <>
      <SectionConnect>
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <Row>
                <Col
                  md={6}
                  data-sal="fade"
                  data-sal-delay="400"
                  data-sal-duration="1000"
                >
                  <h2>Connect with others creating change</h2>
                  <p className="lead">
                    Giving is even better with others. An Impact Account helps
                    you connect with other people and create change together.
                  </p>
                </Col>
                <Col md={6}>
                  <div>
                    <div className="c-ui list">
                      <ListItem
                        role="listitem"
                        data-sal="fade"
                        data-sal-delay="0"
                        data-sal-duration="1000"
                      >
                        <img
                          src="/icons/rallyfriends.svg"
                          className="image"
                          width="42px"
                          height="42px"
                          alt=""
                          loading="lazy"
                        />
                        <div className="content">
                          <b style={{ color: "#424242" }}>
                            Rally friends, family, and other
                          </b>{" "}
                          people in your community and give together with a
                          Giving Group.
                        </div>
                      </ListItem>
                      <ListItem
                        role="listitem"
                        data-sal="fade"
                        data-sal-delay="400"
                        data-sal-duration="1000"
                      >
                        <img
                          src="/icons/findpeople.svg"
                          className="image"
                          width="42px"
                          height="42px"
                          alt=""
                          loading="lazy"
                        />
                        <div className="content">
                          <b style={{ color: "#424242" }}>
                            Find groups of people who share your interests.
                          </b>{" "}
                          Search for Giving Groups that are supporting the
                          causes that matter most to you.
                        </div>
                      </ListItem>
                      <ListItem
                        role="listitem"
                        data-sal="fade"
                        data-sal-delay="800"
                        data-sal-duration="1000"
                      >
                        <img
                          src="/icons/givegift.svg"
                          className="image"
                          width="42px"
                          height="42px"
                          alt=""
                          loading="lazy"
                        />
                        <div className="content">
                          <b style={{ color: "#424242" }}>
                            Give the gift of charitable dollars.
                          </b>{" "}
                          From birthday gifts to children's allowances to "thank
                          you's," send charitable dollars from your account to
                          others for them to give away.
                        </div>
                      </ListItem>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </SectionConnect>
    </>
  );
};

export default SectionTwo;
