import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { VCenter } from "../../styles/globalStyles";
import CounterComponent from "../CounterComponent";

const SectionWrap = styled.section`
  background-color: #f4f8ff;
  min-height: auto;
  padding: 60px 0;
  position: relative;
  ${VCenter}

  &::after {
    content: "";
    position: absolute;
    bottom: -80px;
    right: 0;
    width: 188px;
    height: 202px;
    background-image: url(/index/shape-1-invert.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

const ContentWrap = styled.div`
  text-align: center;
  position: relative;
  z-index: 999;
`;

const StyledH2 = styled.h2`
  font-family: ProximaNova-Semibold;
  color: #282828;
`;

const SectionDonationStatsNew = () => {
  return (
    <SectionWrap id="counter">
      <Container>
        <Row className="justify-content-center">
          <Col md={10}>
            <ContentWrap>
              <StyledH2>
                Join the thousands of Canadians already on Charitable Impact
              </StyledH2>

              <CounterComponent />
            </ContentWrap>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default SectionDonationStatsNew;
