import * as React from "react";
import Layout from "../components/Layout/Layout";

// Components Import
import BannerSection from "../components/Home/BannerSection";
import SectionTabs from "../components/Home/sectionTabs";
import SectionTwo from "../components/Home/sectionTwo";
import OpenAccountComp from "../components/Home/openAccount";
import SectionBlueComp from "../components/Home/sectionBlue";
import SectionPhotoGallery from "../components/Home/sectionGallery";
import SectionFeature from "../components/Home/sectionFeatures";
import SectionDonationStatsNew from "../components/Home/sectionDonationStatsNew";

const pageInfo = {
  path: "/",
  title: "Build a brighter future",
  description:
    "Find Canadian charities, manage your charitable giving, give with others, and create the change you want to see in the world from one account.",
};

// markup
const IndexPage = () => {
  return (
    <Layout pageInfo={pageInfo}>
      <BannerSection />
      <SectionDonationStatsNew />
      <SectionTabs
        bannerHeading="Your space to build a brighter future"
        bannerTextLead="An Impact Account is like an online bank account for charitable giving. Support any registered charity in Canada and manage your giving the way you want to."
        tabHeading1="Find charities"
        tabHeading2="Add money"
        tabHeading3="Give"
        tabTextContent1="Find charities that match your interests and learn more about what they do."
        tabTextContent2="Add money to your account, then take as much time and space as you need to decide which charities to support."
        tabTextContent3="Give to your favourite charities from your account now, or save some of your charitable dollars and build your impact over time. The choice is yours."
        tabImagePath1="/how-it-works/find-charities.png"
        tabImagePath2="/how-it-works/addmoney.png"
        tabImagePath3="/how-it-works/give.png"
        colClassName="col-xl-11 offset-xl-1"
      />
      <SectionTwo />
      <SectionBlueComp />
      <SectionPhotoGallery />
      <SectionFeature />
      <OpenAccountComp />
    </Layout>
  );
};

export default IndexPage;
