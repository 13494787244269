import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import ButtonWithAnchor from "../Buttons/ButtonWithAnchor";

const OpenAccount = styled.section`
  padding: 80px 0 80px;

  .container {
    &.full-open-account {
      @media only screen and (max-width: 767px) {
        width: 100% !important;
        padding: 0;
        margin: 0;
        text-align: center;
        display: inline;
      }
    }
  }
`;

const OpenAccountWraper = styled.div`
  background-color: #242734;
  border-radius: 4px;
  position: relative;
  color: #fff;
  padding: 75px 95px;

  @media only screen and (max-width: 991px) {
    padding: 55px 35px;
  }

  @media only screen and (max-width: 767px) {
    text-align: center;
    padding: 60px 0;
    overflow: hidden;
    border-radius: 0;
  }

  &::before {
    content: "";
    position: absolute;
    left: -70px;
    top: -28px;
    bottom: -29px;
    background-image: url(/bg/dotbg.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 148px;
    z-index: -1;
  }

  &::after {
    content: "";
    position: absolute;
    left: 42px;
    height: 40px;
    bottom: 0;
    width: 110px;
    background-image: url(/bg/bars.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  @media only screen and (max-width: 767px) {
    &::before,
    &::after {
      display: none;
    }
  }

  h3,
  p {
    color: inherit;
    font-family: ProximaNova-Light;
    font-weight: 300;
  }

  h3 {
    @media only screen and (max-width: 991px) {
      font-size: 21px;
    }
  }

  p {
    font-size: 18px;
    margin-bottom: 0;
  }
`;

const OpenAccountWraperIcons = styled.div`
  background-image: url(/bg/halfcircle.svg);
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
  width: 378px;
  top: 0;
  position: absolute;
  right: 0;
  bottom: 0;

  @media (max-width: 991px) {
    background-image: url(/bg/halfcircle.svg);
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
    width: 348px;
    height: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
  }
`;

const TopContent = styled.div`
  position: relative;
  z-index: 999;

  @media only screen and (max-width: 767px) {
    padding-bottom: 20px;
  }
`;

const OpenAccountComp = () => {
  return (
    <>
      <OpenAccount>
        <Container className="full-open-account">
          <OpenAccountWraper>
            <OpenAccountWraperIcons></OpenAccountWraperIcons>
            <Row className="align-items-center">
              <Col md={7} xl={8} lg={8}>
                <TopContent>
                  <h3>Build the brighter future you imagine</h3>
                  <p>Opening a free Impact Account takes about 5 minutes.</p>
                </TopContent>
              </Col>
              <Col md={5} xl={4} lg={4}>
                <ButtonWithAnchor
                  href="https://my.charitableimpact.com/users/new"
                  text="Open an Impact Account"
                />
              </Col>
            </Row>
          </OpenAccountWraper>
        </Container>
      </OpenAccount>
    </>
  );
};

export default OpenAccountComp;
