import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import ButtonWithLink from "../Buttons/ButtonWithLink";

const SectionWrap = styled.section`
  padding: 115px 0 125px;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: 767px) {
    padding: 115px 0 80px;
  }
`;

const MasonaryContent = styled.div`
  position: relative;
  z-index: 2;

  h2 {
    color: #282828 !important;
  }

  p {
    font-family: ProximaNova-Regular;
  }
`;

const RightMasonary = styled.div`
  position: absolute;
  right: -10px;
  z-index: 1;
  bottom: -10px;
  padding: 0 1rem;
  text-align: right;

  .row {
    img {
      margin: 5px 0;
    }

    & > div {
      padding-right: 0;
      padding-left: 0;

      & > span {
        display: inline-block;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const MasonaryCol1 = styled.div`
  -ms-flex: 0 0 230px;
  -webkit-box-flex: 0;
  flex: 0 0 230px;
  width: 230px !important;
  margin: 5px;
  margin-left: auto;
`;

const MasonaryCol2 = styled.div`
  -ms-flex: 0 0 210px;
  -webkit-box-flex: 0;
  flex: 0 0 210px;
  width: 210px !important;
  margin: 5px;

  img {
    width: 100%;
  }
`;

const MasonaryCol3 = styled.div`
  -ms-flex: 0 0 180px;
  -webkit-box-flex: 0;
  flex: 0 0 180px;
  width: 180px !important;
  padding: 5px;
  text-align: center;
`;

const SlideUpAnimation = {
  "data-sal": "slide-up",
  "data-sal-delay": "0",
  "data-sal-duration": "2000",
};

const StyledButtonWithLink = styled(ButtonWithLink)`
  &::before {
    display: none;
  }
`;

const SectionPhotoGallery: React.FC = () => {
  return (
    <>
      <SectionWrap>
        <Container className="photo-gallery-full-width">
          <Row className="justify-content-center">
            <Col md={10}>
              <Row>
                <Col md={6}>
                  <MasonaryContent>
                    <h2>
                      Because the brighter future you imagine is worth having
                    </h2>
                    <p className="lead">
                      An Impact Account gives you a place to think about the
                      change you want to make in the world, plan your giving,
                      and take advantage of tools and support for making your
                      biggest impact possible.
                    </p>
                    <p style={{ color: "#424242" }}>
                      Opening and using an Impact Account is free, and you can
                      get started by adding $5, $500, or more—it's up to you.
                    </p>
                    <StyledButtonWithLink
                      Linkto="/how-it-works"
                      text="How it works"
                      style={{ minWidth: "125px" }}
                      className="my-2"
                    />
                  </MasonaryContent>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        <RightMasonary>
          <Row className="align-items-end">
            <MasonaryCol1>
              <span {...SlideUpAnimation}>
                <StaticImage
                  src="../../images/test/ribbon@2x.png"
                  className="logo-photo"
                  width={55}
                  height={50}
                  alt="image"
                  objectFit="contain"
                  quality={100}
                  loading="eager"
                />
              </span>
              <span {...SlideUpAnimation}>
                <StaticImage
                  src="../../images/test/darkgreen@3x.svg"
                  className="img-fluid"
                  width={230}
                  height={62}
                  alt="image"
                  objectFit="contain"
                  quality={100}
                  loading="eager"
                />
              </span>
            </MasonaryCol1>

            <MasonaryCol2>
              <span {...SlideUpAnimation}>
                <StaticImage
                  src="../../images/test/photo-1.png"
                  className="img-fluid"
                  width={210}
                  height={189}
                  alt="image"
                  objectFit="contain"
                  quality={100}
                  loading="eager"
                  // {...SlideUpAnimation}
                />
              </span>
              <StaticImage
                src="../../images/test/green@3x.svg"
                className="img-fluid"
                width={210}
                height={123}
                alt="image"
                objectFit="contain"
                quality={100}
                loading="eager"
                // {...SlideUpAnimation}
              />
            </MasonaryCol2>

            <MasonaryCol3 {...SlideUpAnimation}>
              <StaticImage
                src="../../images/test/photo-2@2x.png"
                className="img-fluid"
                width={170}
                height={317}
                alt="image"
                objectFit="contain"
                quality={100}
                loading="eager"
                // {...SlideUpAnimation}
              />
              <StaticImage
                src="../../images/test/yellow@3x.svg"
                className="img-fluid"
                width={170}
                height={101}
                alt="image"
                objectFit="contain"
                quality={100}
                loading="eager"
                // {...SlideUpAnimation}
              />
            </MasonaryCol3>
          </Row>
        </RightMasonary>
      </SectionWrap>
    </>
  );
};

export default SectionPhotoGallery;
