import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import { VCenter } from '../../styles/globalStyles';
import ButtonWithAnchor from '../Buttons/ButtonWithAnchor';

const SectionWrap = styled.section`
  position: relative;
  min-height: 520px;
  background-image: url('/index/homepage-bg.png');
  background-size: cover;
  background-position: bottom;
  overflow: hidden;

  @media (max-width: 420px) {
    min-height: 590px;
  }
`;

const BannerContentWrap = styled.div`
  ${VCenter}
  position: relative;
  background-size: cover;
  background-position: bottom;
  max-width: 100%;
  margin: auto;

  .Left_TopImage1 {
    width: 187px;
    height: 124px;
    max-width: initial;

    @media only screen and (max-width: 1199px) {
      position: absolute;
      left: -10px;
      top: 60px;
    }

    @media (max-width: 767px) {
      top: -30px;
    }
  }

  .Left_MiddleImg1 {
    img {
      width: 82px;
      height: 92px;
      -o-object-fit: cover;
      object-fit: cover;

      @media only screen and (max-width: 1199px) {
        height: 52px;
        width: 60px;
        margin-left: 48px;
      }
    }
  }

  .Left_MiddleImg2 {
    img {
      height: 94px;
      width: 165px;
      -o-object-fit: cover;
      object-fit: cover;

      @media only screen and (max-width: 1199px) {
        height: 60px;
        width: 108px;
      }
    }
  }

  .Left_MiddleImg3 {
    img {
      height: 145px;
      width: 168px;
      -o-object-fit: cover;
      object-fit: cover;

      @media only screen and (max-width: 1199px) {
        height: 105px;
        width: 108px;
      }
    }
  }

  .Left_MiddleImg4 {
    vertical-align: baseline;

    @media only screen and (max-width: 1199px) {
      margin-top: 320px;
      margin-left: -20px;
    }

    @media only screen and (max-width: 992px) and (min-width: 767px) {
      margin-top: 201px;
      margin-left: -20px;
    }

    @media only screen and (max-width: 991px) {
      top: 110px;
    }
  }

  .Right_TopImg2 {
    width: 92px;
    height: 80px;
    -o-object-fit: cover;
    object-fit: cover;
    max-width: initial;

    @media only screen and (max-width: 1350px) {
      margin-right: -50px;
    }

    @media only screen and (max-width: 1199px) {
      display: none;
    }
  }

  .Right_TopImg3 {
    width: 86px;
    height: 84px;

    @media (max-width: 767px) {
      margin-top: 325px;
      margin-right: -2px;
      width: 75px;
    }

    @media (max-width: 420px) {
      margin-top: 395px;
    }
  }

  .Right_TopImg4 {
    width: 82px;
    height: 142px;
    margin-left: -2px;
    -o-object-fit: cover;
    object-fit: cover;
    margin-right: 50px;

    @media only screen and (max-width: 1199px) {
      margin-left: -4px;
      margin-right: 0;
    }
  }

  .Right_MiddleImg1 {
    width: 168px;
    height: 142px;

    @media only screen and (max-width: 1199px) {
      height: 100px;
      width: 100px;
      margin-right: 164px;
    }

    @media only screen and (max-width: 992px) and (min-width: 767px) {
      height: 65px;
      width: 77px;
      margin-right: 163px;
    }

    @media only screen and (max-width: 767px) {
      margin-right: -4px;
    }
  }

  .Right_MiddleImg2 {
    width: 216px;
    height: 116px;

    @media only screen and (max-width: 1199px) {
      display: none;
    }
  }

  .Right_BottomImg1 {
    img {
      width: 99px;
      height: 60px;

      @media only screen and (max-width: 992px) and (min-width: 767px) {
        width: 60px;
      }
    }
  }

  .Right_BottomImg2 {
    width: 64px;
    height: 11px;
    vertical-align: baseline;
  }
`;

const BannerContent = styled.div`
  display: inline-block;
  text-align: center;
  max-width: 460px;
  margin: auto;
  padding: 1rem;
  position: relative;
  z-index: 10;

  &::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    width: 0;
  }

  @media only screen and (max-width: 767px) {
    margin-top: 120px !important;
  }
`;

const BannerHeading = styled.h1`
  margin-top: 84px;
  color: #424242;

  @media (max-width: 767px) {
    margin: 0;
  }
`;

const BannerSubHeading = styled.p`
  color: #282828;
  font-size: 30px;
  line-height: 42px;
  font-family: ProximaNova-Light;
  font-weight: 300;

  @media only screen and (max-width: 1199px) {
    font-size: 24px;
    line-height: 34px;
  }
`;

const BannerText = styled.p`
  font-size: 18px;
  color: #424242;
  font-family: ProximaNova-Regular;
`;

const TileImages = styled.div`
  position: absolute;
  top: 0;
  background-position: center;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  height: 520px;
  width: 50%;

  &.left_tile {
    top: 20%;
    left: 50px;
    text-align: left;

    @media only screen and (max-width: 1199px) {
      top: 0;
      left: 0;
    }

    .left_tile--top {
      span {
        @media only screen and (max-width: 767px) {
          display: initial;
        }
      }
    }

    .left_tile--middle {
      @media only screen and (max-width: 1199px) {
        position: absolute;
        bottom: 34%;
      }

      @media only screen and (max-width: 800px) {
        bottom: 35%;
        margin-left: -25px;
      }
    }

    .left_tile--middle,
    .left_tile--bottom {
      @media only screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  &.right_tile {
    top: 0;
    right: 0;
    text-align: right;

    .right_tile--top {
      & > span {
        vertical-align: bottom;

        &:not(:nth-of-type(3)) {
          @media (max-width: 767px) {
            display: none;
          }
        }
      }
    }

    .right_tile--bottom {
      @media only screen and (max-width: 767px) {
        display: none;
      }

      span:nth-child(2) {
        margin-top: 43px;

        @media only screen and (max-width: 1199px) {
          margin-right: -20px !important;
          margin-top: 44px;
        }

        @media only screen and (max-width: 992px) and (min-width: 767px) {
          margin-right: 0 !important;
          margin-top: 20px;
        }
      }
    }
  }
`;

const VTop = styled.div`
  vertical-align: bottom;

  span {
    vertical-align: top;
    display: inline-block;
  }
`;

const Right_TopImg1 = styled.img`
  margin-right: -40px;
  max-width: initial;

  &.d-block {
    @media only screen and (max-width: 1920px) and (min-width: 1350px) {
      margin-right: 10px;
    }

    @media only screen and (max-width: 1199px) and (min-width: 767px) {
      display: none !important;
    }
  }
`;

const Right_TopSpace = styled.div`
  min-width: 164px;
`;

const StyledButtonWithAnchor = styled(ButtonWithAnchor)`
  font-size: 14px;
  padding: 10px 40px;
  &::before {
    display: none;
  }
`;

const BannerSection = () => {
  return (
    <SectionWrap>
      <BannerContentWrap>
        <TileImages className='left_tile'>
          <VTop className='left_tile--top mb-3 ms-5 ps-2'>
            <span className='animate__animated animate__fadeInDown animate__slow'>
              <StaticImage
                alt=''
                src='../../images/index/shape-1.png'
                className='Left_TopImage1'
                layout='constrained'
                placeholder='tracedSVG'
                quality={100}
                loading='eager'
              />
            </span>
          </VTop>
          <VTop className='left_tile--middle'>
            <span className='animate__animated animate__fadeInUp animate__slow text-end'>
              <StaticImage
                alt=''
                src='../../images/index/photo-5.png'
                className='Left_MiddleImg1'
                layout='constrained'
                placeholder='tracedSVG'
                quality={100}
                loading='eager'
              />
              <br />
              <StaticImage
                alt=''
                src='../../images/index/photo-2.jpg'
                className='Left_MiddleImg2'
                height={94}
                layout='constrained'
                placeholder='tracedSVG'
                quality={100}
                loading='eager'
              />
              <br />
            </span>
            <span className='animate__animated animate__fadeInUp animate__slow animate__delay-1s'>
              <StaticImage
                alt=''
                src='../../images/index/photo-3.png'
                className='Left_MiddleImg3'
                height={145}
                layout='constrained'
                placeholder='tracedSVG'
                quality={100}
                loading='eager'
              />
            </span>
          </VTop>
          <VTop className='left_tile--bottom'>
            <span className='animate__animated animate__fadeInUp animate__slow text-start'>
              <StaticImage
                alt=''
                src='../../images/index/shape-5.png'
                className='Left_MiddleImg4'
                layout='constrained'
                placeholder='tracedSVG'
                quality={100}
                loading='eager'
              />
            </span>
          </VTop>
        </TileImages>

        <TileImages className='right_tile'>
          <VTop className='right_tile--top'>
            <span className='animate__animated animate__fadeInDown animate__slow'>
              <Right_TopImg1
                className='d-block pb-2 pe-5'
                src='/index/shape-5.png'
              />
              <StaticImage
                alt=''
                src='../../images/portrait-4.jpg'
                height={80}
                className='Right_TopImg2'
                layout='constrained'
                placeholder='tracedSVG'
                quality={100}
                loading='eager'
              />
            </span>

            <span className='animate__animated animate__fadeInDown animate__slow animate__delay-1s'>
              <Right_TopSpace />
            </span>

            <span className='animate__animated animate__fadeInDown animate__slow animate__delay-2s'>
              <StaticImage
                alt=''
                src='../../images/shape-3.png'
                className='Right_TopImg3'
                layout='constrained'
                placeholder='tracedSVG'
                quality={100}
                loading='eager'
              />
            </span>

            <span className='animate__animated animate__fadeInRight animate__slow animate__delay-3s'>
              <StaticImage
                alt=''
                src='../../images/portrait-6.jpg'
                className='Right_TopImg4'
                layout='constrained'
                placeholder='tracedSVG'
                quality={100}
                loading='eager'
              />
            </span>
          </VTop>

          <VTop className='right_tile--middle'>
            <span className='animate__animated animate__fadeInUp animate__slow'>
              <StaticImage
                alt=''
                src='../../images/portrait-5.png'
                className='Right_MiddleImg1'
                layout='constrained'
                placeholder='tracedSVG'
                quality={100}
                loading='eager'
              />
            </span>

            <span className='animate__animated animate__fadeInUp animate__slow animate__delay-1s'>
              <StaticImage
                alt=''
                src='../../images/photo-7.png'
                className='Right_MiddleImg2'
                layout='constrained'
                height={116}
                placeholder='tracedSVG'
                quality={100}
                loading='eager'
              />
            </span>
          </VTop>

          <VTop className='right_tile--bottom mt-4'>
            <span className='animate__animated animate__fadeInUp animate__slow me-3'>
              <StaticImage
                alt=''
                src='../../images/index/shape-2.png'
                className='Right_BottomImg1'
                layout='constrained'
                placeholder='tracedSVG'
                quality={100}
                objectFit='fill'
                loading='eager'
              />
            </span>
            <span className='animate__animated animate__fadeInUp animate__slow animate__delay-1s me-5 pe-5'>
              <StaticImage
                alt=''
                src='../../images/index/shape-3.png'
                className='Right_BottomImg2'
                layout='constrained'
                placeholder='tracedSVG'
                quality={100}
                loading='eager'
              />
            </span>
          </VTop>
        </TileImages>

        <BannerContent id='bannerContent'>
          <BannerHeading />
          <BannerSubHeading>
            Imagine a brighter future.
            <br /> Let's make it happen.
          </BannerSubHeading>
          <BannerText>
            Manage your charitable giving and create the change you want to see
            in the world. All from one place.
          </BannerText>
          <p>
            <StyledButtonWithAnchor
              text='Open an Impact Account'
              href='https://my.charitableimpact.com/users/new'
              className='my-2'
            />
          </p>
        </BannerContent>
      </BannerContentWrap>
    </SectionWrap>
  );
};

export default BannerSection;
